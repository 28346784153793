<template>
	<div class="row">
		<div class="col-lg-12 col-xxl-12 order-5 order-xxl-5">
			<div v-if="!viewAs.username && ['admin', 'noc', 'staff'].includes(this.currentUser.role)">
				<h6>
					<i 
						class="fa fa-exclamation-circle" 
						aria-hidden="true" 
						style="color:red;  background-image: radial-gradient(at center, white 40%, transparent 40%);"
					></i>
					Use "View As User" to see this page. Too much data to show all the sites at once.
				</h6>
			</div>
			<div v-for="(site, sIdx) in sitegraphs" v-bind:key="sIdx">

				<router-link :to="{ name: 'site', params: { id: site.SiteId } }" >
					<h3>{{site.Name}}</h3>
				</router-link>

				<div v-for="(graph, gIdx) in site.graphsToShow" v-bind:key="gIdx">
					<component :is="graph.name" v-bind="graph.props" />
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

const component_names = [
	{ widgetId: 2, name: 'IdirectGraphs' },
	{ widgetId: 22, name: 'SolarwindsGraphs' },
	{ widgetId: 24, name: 'SolarwindsIPSLA' },
	{ widgetId: 25, name: 'Solarwinds4G' },
	{ widgetId: 26, name: 'SolarwindsComtechEbN0' },
	{ widgetId: 27, name: 'SolarwindsComtechPower' },
	{ widgetId: 28, name: 'SolarwindsComtechTemp' },
	{ widgetId: 29, name: 'SolarwindsComtechInterfaces' }
];

export default {
    name: 'SiteListGraphs',
    data() {
		return {
			loaded: false,
			updated: null,
			sitelist: [],
			opts: {},
			sitegraphs: {},
			widgets: {},
			promises: {
				comtech: {},
				modemStatus: null,
			},
			error: false,
		}
    },
	components: {
        ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		DateRangeSelector: () => import('@/view/content/widgets/components/DateRangeSelector.vue'),
		Widget: () => import('@/view/content/widgets/site/Widget.vue'),
		IdirectModemStatus: () => import('@/view/content/widgets/site/IdirectModemStatus.vue'),
		IdirectGraphs: () => import('@/view/content/widgets/site/IdirectGraphs.vue'),
		SiteMap: () => import('@/view/content/widgets/site/SiteMap.vue'),
		IdirectDetails: () => import('@/view/content/widgets/site/IdirectDetails.vue'),
		Idirect24HourUsage: () => import('@/view/content/widgets/site/Idirect24HourUsage.vue'),
		IdirectIPInfo: () => import('@/view/content/widgets/site/IdirectIPInfo.vue'),
		SiteVoip: () => import('@/view/content/widgets/site/SiteVoip.vue'),
		IdirectBeamSwitches: () => import('@/view/content/widgets/site/IdirectBeamSwitches.vue'),
		Exinda: () => import('@/view/content/widgets/site/Exinda.vue'),
		IdirectUsageByProtocol: () => import('@/view/content/widgets/site/IdirectUsageByProtocol.vue'),
		SolarTransit: () => import('@/view/content/widgets/site/SolarTransit.vue'),
		IdirectHeatMap: () => import('@/view/content/widgets/site/IdirectHeatMap.vue'),
		SolarwindsGraphs: () => import('@/view/content/widgets/site/SolarwindsGraphs.vue'),
		SolarwindsIPSLA: () => import('@/view/content/widgets/site/SolarwindsIPSLA.vue'),
		SolarwindsIPSLAAverages: () => import('@/view/content/widgets/site/SolarwindsIPSLAAverages.vue'),
		Solarwinds4G: () => import('@/view/content/widgets/site/Solarwinds4G.vue'),
		SolarwindsComtechEbN0: () => import('@/view/content/widgets/site/SolarwindsComtechEbN0.vue'),
		SolarwindsComtechPower: () => import('@/view/content/widgets/site/SolarwindsComtechPower.vue'),
		SolarwindsComtechTemp: () => import('@/view/content/widgets/site/SolarwindsComtechTemp.vue'),
		SolarwindsComtechInterfaces: () => import('@/view/content/widgets/site/SolarwindsComtechInterfaces.vue'),
		SolarwindsDailyGraph: () => import('@/view/content/widgets/site/SolarwindsDailyGraph.vue'),
		SolarTransitComtech: () => import('@/view/content/widgets/site/SolarTransitComtech.vue'),
		SolarwindsNetFlow: () => import('@/view/content/widgets/site/SolarwindsNetFlow.vue'),
		SiteComtechMap: () => import('@/view/content/widgets/site/SiteComtechMap.vue'),
		SolarwindsAvailability: () => import('@/view/content/widgets/site/SolarwindsAvailability.vue'),
		SiteQuota: () => import('@/view/content/widgets/site/SiteQuota.vue'),
	},
    computed: {
		...mapGetters(['viewAs', 'currentUser']),
    },
    methods: {
		setComponentProps(sitegraph) {
			this.updated = Date.now();
			var components = {};
					let site = sitegraph;

			Object.entries(site.graphsToShow).forEach(([key, widget]) => {

				let compo = component_names.find(c => c.widgetId == widget.WidgetId);

				let comp = {
					widgetId: compo.widgetId,
					name: compo.name,
				};

				switch (comp.name) {
					// 2
					case 'IdirectGraphs':
						comp.props = {
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
							graphs: site.devices[widget.DeviceId].stats?.data?.graphs,
							properties: widget?.properties,
							rates: site.devices[widget.DeviceId].stats?.data?.rates[site.devices[widget.DeviceId].stats?.data?.currentnet?.NetworkId],
						};
						break;
					// 22
					case 'SolarwindsGraphs':
						comp.props = {
							id: widget.DeviceId,
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
							device: site.devices[widget.DeviceId],
							properties: widget?.properties,
							updated: this.updated,
						};
						break;
					// 24
					case 'SolarwindsIPSLA':
						comp.props = {
							id: widget.DeviceId,
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
							ipsla: widget.properties?.ipsla,
							properties: widget?.properties,
							devices: site.devices,
							updated: this.updated,
						};

						break;
					// 25
					case 'Solarwinds4G':
						comp.props = {
							id: widget.DeviceId,
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
						};
						break;
					// 26
					case 'SolarwindsComtechEbN0':
						comp.props = {
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
							prom: this.getPromise('comtech', site.devices[widget.DeviceId].Source),
						};
						break;
					// 27
					case 'SolarwindsComtechPower':
						comp.props = {
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
							prom: this.getPromise('comtech', site.devices[widget.DeviceId].Source),
						};
						break;
					// 28
					case 'SolarwindsComtechTemp':
						comp.props = {
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
							prom: this.getPromise('comtech', site.devices[widget.DeviceId].Source),
						};
						break;
					// 29
					case 'SolarwindsComtechInterfaces':
						comp.props = {
							name: widget.properties?.label || site.devices[widget.DeviceId].Name,
							prom: this.getPromise('comtech', site.devices[widget.DeviceId].Source),
						};
						break;
				}
				components[comp.widgetId] = comp;
			});//forEach site(graphs to show)-widget

			return components;
		},
		getPromise(widget, source) {
			switch (widget) {
				case 'comtech':
					if (!this.promises.comtech[source]) {
						this.promises.comtech[source] = this.$http.get(`swcomtech/${source}`);
					}
					return this.promises.comtech[source];
				case 'modemStatus':
					if (!this.promises.modemStatus) {
						this.promises.modemStatus = this.$http.get(`sitestatus/${this.$route.params.id}`);
					}
					return this.promises.modemStatus;
			}
		},
		async getSiteListGraphs() {
			this.loaded = false;
			this.$http.get('sitelist').then(res => {
				const alarm = 0, warning = 1, ok = 2, offline = 3, deactivated = 4, unknown = 5, empty = 6;
				// console.log("fetch sitelist", res.data.data)
				res.data.data.forEach(site => {
					site.max_status = 6;
					// console.log('siteD', site.devices)
					for (const [deviceID, device] of Object.entries(site.devices)) {
						if(device.status !== undefined && device.status !== null) {
							if(device.status == 'ALARM' && site.max_status > alarm)	{
								site.max_status = alarm;
							}
							else if(device.status == 'WARNING' && site.max_status > warning)	{
								site.max_status = warning;
							}
							else if(device.status == 'OK' && site.max_status > ok)	{
								site.max_status = ok;
							}
							else if(device.status == 'OFFLINE' && site.max_status > offline)	{
								site.max_status = offline;
							}
							else if(device.status == 'DEACTIVATED' && site.max_status > deactivated)	{
								site.max_status = deactivated;
							}
							else if(device.status == 'UNKNOWN' && site.max_status > unknown)	{
								site.max_status = unknown;
							}
						};
					};
				});

				this.sitelist = res.data.data;

			}).then(() => {
				//BEGIN: create filter to remove graphs for Northstar Account
				let userfilter = null; 
				if(this.viewAs.AccountID != null) {
						userfilter = this.viewAs.AccountID;
					} else {
						userfilter = this.currentUser.AccountID;
					} 
				//END: create filter to remove graphs for Northstar Account

				const graphsToShow = [2,22,24,25,26,27,28,29]; // widget ids of graphs to show

				this.sitelist.forEach(sl => {
						let sitegraph = {}
						sitegraph[sl.SiteId] = {};
						this.opts.id = sl.SiteId;

						this.$http.get(`site/${this.opts.id}`).then(res => {
							sitegraph[sl.SiteId] = res.data.data;
							sitegraph[sl.SiteId].graphsToShow = {};

							res.data.data.layout.bootlayout.forEach((row) => {
								row.forEach((col) => {
									if(graphsToShow.includes(col.WidgetId)){
										if(col.WidgetId == 22){	//create filter to remove vlan graphs also widgetid 22 as 4G LTE for Northstar Account
											if(this.viewAs.AccountID == 739 || this.currentUser.AccountID == 739) { // Northstar account ID
												if(res.data.data.devices[col.DeviceId].Name.includes('4G LTE')) {
													sitegraph[sl.SiteId].graphsToShow[col.WidgetId] = col;
												}
											} else {
												sitegraph[sl.SiteId].graphsToShow[col.WidgetId] = col;
											} 
										} else {
											sitegraph[sl.SiteId].graphsToShow[col.WidgetId] = col;
										}
									}
								}); // foreach col
							}); //foreach row
							
							let components = this.setComponentProps(sitegraph[sl.SiteId])

							Object.entries(sitegraph[sl.SiteId].graphsToShow).forEach((key, widget) => {
								sitegraph[sl.SiteId].graphsToShow = Object.assign({}, widget, components)
							})

							this.sitegraphs = Object.assign({}, this.sitegraphs, sitegraph)
						})
				}); // site q
			}).then(
				this.loaded=true
			)
		},

    },
	async created() {
		if (this.viewAs.username != null || this.currentUser.role==='user') {
			this.opts.username = this.viewAs.username;
			await this.getSiteListGraphs();
		} else if(['admin', 'noc', 'staff'].includes(this.currentUser.role)){ // if admin and not viewing users don't load shit
			return;
		}
		
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Site List Graphs' }]);
	}
};
</script>

<style>
</style>